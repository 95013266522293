import ForSaleSwitches from "../utils/ForSaleSwitches";

export default class ProductIndex {
  constructor() {
    this.switches = null;
    this.datatable = null;
    this.bindEvents();
  }

  bindEvents() {
    const self = this;

    let show_actions = $('#products-datatable').data("show-actions") == true;
    
    var columns = [
      { "data": "type" },
      { "data": "name" },
      { "data": "unit" },
      { "data": "fas" },
      { "data": "subscription_price" },
      { "data": "commitment_period" }
    ];
  
    if (show_actions) {
        columns.push({ "data": "for_sale" });
        columns.push({ "data": "actions" });
    }

    self.datatable = $("#products-datatable").DataTable({
      "ajax": {
        'url': $('#products-datatable').data("url"),
        data: function (d) {
          d.order_by = $('#order_by').val();
          d.filter_fas = $('#fas-select').val();
          d.filter_subscription = $('#subscription-select').val();
          d.filter_unit = $('#unit-select').val();
          d.filter_duration = $('#duration-select').val();
        }
      },
      "language": {
        url: '/data-tables/locales/' + I18n.locale + '.json'
      },
      "order": [],
      "columns": columns,
      drawCallback:function(){
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
        self.switches = new ForSaleSwitches();
      }
    });

    $(".filter-select").on("change", function() {
      self.onFilterChange(self);
    });

    $("#file-import-input").dropify({
      messages: {
      'default': I18n.t('plugins.dropify.messages.default'),
      'replace': I18n.t('plugins.dropify.messages.replace'),
      'remove': I18n.t('plugins.dropify.messages.remove'),
      'error': I18n.t('plugins.dropify.messages.error')
      },
      error: {
      'fileSize': I18n.t('plugins.dropify.error.fileSize'),
      'minWidth': I18n.t('plugins.dropify.error.minWidth'),
      'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
      'minHeight': I18n.t('plugins.dropify.error.minHeight'),
      'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
      'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
      'fileExtension': I18n.t('plugins.dropify.error.fileExtension'),
      }
    });

    // Handle export links
    $("#export-csv").on("click", function(e) {
      e.preventDefault();
      self.exportProducts('csv');
    });

    $("#export-excel").on("click", function(e) {
      e.preventDefault();
      self.exportProducts('xlsx');
    });
  }

  onFilterChange(self) {
    self.datatable.ajax.reload();
  }

  exportProducts(format) {
    const params = {
      order_by: $('#order_by').val(),
      filter_fas: $('#fas-select').val(),
      filter_subscription: $('#subscription-select').val(),
      filter_unit: $('#unit-select').val(),
      filter_duration: $('#duration-select').val()
    };

    const queryString = $.param(params);
    const url = `/products/export_products.${format}?${queryString}`;
    window.location.href = url;
  }

  onDataChanged(data) {}

  onDestroy() {
    this.switches.onDestroy();
    this.datatable.destroy();
  }
}