import BackofficeIndexBase from './BackofficeIndexBase';

export default class BackofficeOrdersIndex extends BackofficeIndexBase {
  constructor() {
    super("#orders-datatable");
  }

  onDataChanged(data) {
    // Custom logic for orders
  }
}