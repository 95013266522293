export default class BackofficeCustomFieldsForm {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {       
    $(".restricted-btn").on("click", function() {
      $("#restricted-users-wrapper").show();
    });

    $(".regular-btn").on("click", function() {
      $("#restricted-users-wrapper").hide();
    });

    $("#custom_field_type").on("change", function() {
      const fieldType = $(this).val();
      console.log(fieldType);
      
      if (fieldType == 'field_type_date') {
        $(".date-format").show();
        $(".min-text-size, .max-text-size, .min-value, .max-value, .select-options").hide();
      } else if (fieldType == 'field_type_text') {
        $(".min-text-size, .max-text-size").show();
        $(".date-format, .min-value, .max-value, .select-options").hide();
      } else if (fieldType == 'field_type_number' || fieldType == 'field_type_price') {
        $(".min-value, .max-value").show();
        $(".date-format, .min-text-size, .max-text-size, .select-options").hide();
      } else if (fieldType == 'field_type_select') {
        $(".select-options").show();
        $(".date-format, .min-text-size, .max-text-size, .min-value, .max-value").hide();
      } else {
        $(".date-format, .min-text-size, .max-text-size, .min-value, .max-value, .select-options").hide();
      }
    });

    $("#add-select-option").on("click", function() {
      const newOption = `
        <div class="select-option d-flex align-items-center mb-1">
          <div class="w-100">
            <input type="text" name="custom_field_template[select_options][]" class="form-control" />
          </div>
          <button type="button" class="btn btn-danger remove-option ml-1"><i class="fas fa-trash-alt"></i></button>
        </div>`;
      $("#select-options-container").append(newOption);
    });

    $(document).on("click", ".remove-option", function() {
      $(this).closest(".select-option").remove();
    });

    $("#custom_field_restricted_teams").select2({
      multiple: true,
      closeOnSelect: false,
      allowClear: true,
      placeholder: "Sélectionner une ou plusieurs équipe(s)"
    });

    $("#custom_field_restricted_entities").select2({
      multiple: true,
      closeOnSelect: false,
      allowClear: true,
      placeholder: "Sélectionner un ou plusieurs opérateur(s)"
    });

    $("#customSwitchMultiWorkflow").prop("checked", false);
    $("#workflow-template-ids").val(null).trigger('change');

    $("#customSwitchMultiWorkflow").on("change", function() {
      if ($(this).is(":checked")) {
        $("#workflow-templates").show();
      } else {
        $("#workflow-templates").hide();
      }
    });

    $("#workflow-template-ids").select2({
      multiple: true,
      closeOnSelect: false,
      allowClear: true,
      placeholder: "Sélectionner un ou plusieurs workflow(s)"
    });
  }

  onDataChanged(data) {
  }

  onDestroy() {
    $("#custom_field_restricted_teams").select2('destroy');
    $("#custom_field_restricted_entities").select2('destroy');
    $("#workflow-template-ids").select2('destroy');
  }
}