import { t } from "i18n-js";

export default class DraggableTable {
  constructor(tableId) {
    this.tableId = tableId;
    this.draggedRow = null;
    this.bindDragEvents();
  }

  bindDragEvents() {
    const tbody = document.getElementById(this.tableId);

    const tableElement = document.getElementById(this.tableId);
    if (!tableElement) {
      return;
    }

    tbody.addEventListener('dragstart', (e) => this.handleDragStart(e));
    tbody.addEventListener('dragend', (e) => this.handleDragEnd(e));
    tbody.addEventListener('dragover', (e) => this.handleDragOver(e));
    tbody.addEventListener('dragleave', (e) => this.handleDragLeave(e));
    tbody.addEventListener('drop', (e) => this.handleDrop(e));
  }

  handleDragStart(e) {
    this.draggedRow = e.target;
    e.target.style.opacity = 0.5;
  }

  handleDragEnd(e) {
    e.target.style.opacity = '';
    this.draggedRow = null; // Reset draggedRow after drag ends
  }

  handleDragOver(e) {
    e.preventDefault(); // Necessary for the drop event to work
    const target = e.target.closest('tr');
    if (target && target !== this.draggedRow) {
      const { y, height } = target.getBoundingClientRect();
      const offset = y + height / 2;
      if (e.clientY - offset > 0) {
        this.setDropIndicator(target, 'bottom');
      } else {
        this.setDropIndicator(target, 'top');
      }
    }
  }

  handleDragLeave(e) {
    const target = e.target.closest('tr');
    if (target) {
      this.clearDropIndicator(target);
    }
  }

  handleDrop(e) {
    e.preventDefault();
    const target = e.target.closest('tr');
    if (target && target !== this.draggedRow) {
      const { y, height } = target.getBoundingClientRect();
      const offset = y + height / 2;
      if (e.clientY - offset > 0) {
        target.after(this.draggedRow);
      } else {
        target.before(this.draggedRow);
      }
      this.clearDropIndicator(target);
      this.updateOrder();
    }
  }

  setDropIndicator(target, position) {
    if (position === 'bottom') {
      target.style.borderBottom = '2px solid #000';
      target.style.borderTop = '';
    } else {
      target.style.borderTop = '2px solid #000';
      target.style.borderBottom = '';
    }
  }

  clearDropIndicator(target) {
    target.style.borderBottom = '';
    target.style.borderTop = '';
  }

  updateOrder() {
    // => override this method in your class to send the new order to the server with ajax
  }
}