import ForSaleSwitches from "../utils/ForSaleSwitches";

export default class BackofficeEntityProductsIndex {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
      let self = this;
      
      $(".js-switch").each((index, element) => {
        var id = $(element).data("id");
        var entityId = $(element).data("entity-id");
        var isEntityProduct = $(element).data("is-entity-product");

        $(element).unbind("change").change(function(e) {
          self.toggle_permissions(id, $(e.target).is(':checked') ? '1' : '0', entityId, isEntityProduct);
        });
      });  
      
      $("#file-import-input").dropify({
        messages: {
        'default': I18n.t('plugins.dropify.messages.default'),
        'replace': I18n.t('plugins.dropify.messages.replace'),
        'remove': I18n.t('plugins.dropify.messages.remove'),
        'error': I18n.t('plugins.dropify.messages.error')
        },
        error: {
        'fileSize': I18n.t('plugins.dropify.error.fileSize'),
        'minWidth': I18n.t('plugins.dropify.error.minWidth'),
        'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
        'minHeight': I18n.t('plugins.dropify.error.minHeight'),
        'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
        'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
        'fileExtension': I18n.t('plugins.dropify.error.fileExtension'),
        }
      });
    }
  
    onDataChanged(data) {
  
    }
  
    onDestroy() {
      $(".js-switch").unbind("change");
    }
  
    toggle_permissions(id, value, entityId, isEntityProduct) {
      let data = {};
      let field_value = {};
      field_value["for_sale"] = value
      data["entity_product"] = field_value
      data["is_entity_product"] = isEntityProduct
  
      let ajax_data = {
        url: "/entities/" + entityId + "/entity_products/" + id + "/ajax_update",
        data: data,
        method: "put",
        success: function (data){
          application.successNotification(I18n.t("backoffice.products.update.success"))
        },
        error: function (err){
          application.errorNotification(I18n.t("backoffice.products.update.error"))
        }
      }
  
      $.ajax(ajax_data)
    }
}