export default class BackofficeEntitiesForm {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
      this.initAutocompletePlaces();
    }  
    

    initAutocompletePlaces() {
      const options = {
          componentRestrictions: { country: "fr" },
          types: ['address'],
      };

      this.placesAddress = new google.maps.places.Autocomplete(document.getElementById('entity_address'), options);
    }
  
    onDataChanged(data) {
  
    }
  
    onDestroy() {
      
    }
  
    toggle_permissions(id, value, entityId, isEntityoption) {
      
    }
}