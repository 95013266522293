export default class BackofficeServicesIndex {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
      $(".custom-control-input").off('change', this.toggleWorkflow).on('change', this.toggleWorkflow);
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    toggleWorkflow(e) {
      const checkbox = e.target;
      const url = checkbox.dataset.url;
      const checked = checkbox.checked;
      
      $.ajax({
        url: url,
        method: 'PUT',
        data: { active: checked },
        success: function() {
          application.successNotification(I18n.t("backoffice.services.updated"))
        },
        error: function() {
          application.errorNotification(I18n.t("backoffice.services.update.error"))
        }
      });
    }
}