import BackofficeIndexBase from './BackofficeIndexBase';

export default class BackofficeStudyRequestsIndex extends BackofficeIndexBase {
  constructor() {
    super("#sr-datatable");
  }

  onDataChanged(data) {
    // Custom logic for study requests
  }
}