import DraggableTable from '../utils/DraggableTable';

export default class BackofficeWorkflowsIndex extends DraggableTable {
  constructor() {
    super('draggable-tbody'); // Call the parent class's constructor with the tableId
    this.bindEvents();
  }

  bindEvents() {
    $(".custom-control-input").off('change', this.toggleWorkflow).on('change', this.toggleWorkflow);
  }

  updateOrder() {
    const rows = document.querySelectorAll(`#${this.tableId} tr`);
    const ids = Array.from(rows).map(row => row.getAttribute('data-id'));
    const url = $("#draggable-tbody").data('url');

    $.ajax({
      url: url,
      method: 'PUT',
      data: { workflows_ids: ids },
      error: function() {
        application.errorNotification(I18n.t("backoffice.workflows.update.error"))
      }
    });
  }

  toggleWorkflow(e) {
    const checkbox = e.target;
    const url = checkbox.dataset.url;
    const checked = checkbox.checked;
    
    $.ajax({
      url: url,
      method: 'PUT',
      data: { active: checked },
      success: function() {
        application.successNotification(I18n.t("backoffice.workflows.update.success"))
      },
      error: function() {
        application.errorNotification(I18n.t("backoffice.workflows.update.error"))
      }
    });
  }

  onDataChanged(data) {

  }

  onDestroy() {

  }
}