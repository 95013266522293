import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import EligibilityRequestsIndex from "../controllers/EligibilityRequestsIndex";
import InvoiceIndex from "../controllers/InvoiceIndex";
import InvoiceForm from "../controllers/InvoiceForm";
import SharedFileForm from "../controllers/SharedFileForm";
import DownloadablesForm from "../controllers/DownloadablesForm";
import ProductNew from "../controllers/ProductNew";
import ProductIndex from "../controllers/ProductIndex";
import ProductEdit from "../controllers/ProductEdit";
import OptionIndex from "../controllers/OptionIndex";
import BackofficeEntityProductsIndex from "../controllers/BackofficeEntityProductsIndex";
import BackofficeEntityOptionsIndex from "../controllers/BackofficeEntityOptionsIndex";
import BackofficeOrdersShow from "../controllers/BackofficeOrdersShow";
import BackofficeTasksShow from "../controllers/BackofficeTasksShow";
import BackofficeTasksIndex from "../controllers/BackofficeTasksIndex";
import BackofficeOrdersIndex from "../controllers/BackofficeOrdersIndex";
import BackofficeStudyRequestsIndex from "../controllers/BackofficeStudyRequestsIndex";
import BackofficeWorkflowsIndex from "../controllers/BackofficeWorkflowsIndex";
import BackofficeWorkflowsShow from "../controllers/BackofficeWorkflowsShow";
import BackofficeStepsShow from "../controllers/BackofficeStepsShow";
import BackofficeStepsNew from "../controllers/BackofficeStepsNew";
import BackofficeCustomFieldsForm from "../controllers/BackofficeCustomFieldsForm";
import BackofficeServicesIndex from "../controllers/BackofficeServicesIndex.js";
import BackofficeEligibilitiesIndex from "../controllers/BackofficeEligibilitiesIndex";
import BackofficeEligibilitiesShow from "../controllers/BackofficeEligibilitiesShow";
import BackofficeOrdersNew from "../controllers/BackofficeOrdersNew";
import BackofficeOperationsIndex from "../controllers/BackofficeOperationsIndex";
import BackofficeEntitiesForm from "../controllers/BackofficeEntitiesForm.js";

export default class Routes extends ConfigRoutes{

    static routes = {
        //"DashboardIndex": DashboardIndex,
        "BackofficeBackofficeDashboardTestMarkers": DashboardIndex,
        "BackofficePermissionsIndex": PermissionsIndex,
        "BackofficeAdminsIndex": AdminsIndex,
        "BackofficeAdminsEdit": AdminsEdit,
        "BackofficeAdminsUpdate": AdminsEdit,
        "BackofficeAdminsNew": AdminsNew,
        "BackofficeAdminsCreate": AdminsNew,
        "BackofficeRolesIndex": RoleIndex,
        "BackofficeRolesEdit": RoleEdit,
        "BackofficeRolesNew": RoleEdit,
        "BackofficeRolesUpdate": RoleEdit,
        "BackofficeRolesCreate": RoleEdit,
        "BackofficeProfileIndex": ProfileIndex,
        // Eligibility Requests
        "BackofficeEligibilityRequestsIndex": EligibilityRequestsIndex,
        "BackofficeEligibilityRequestsFtto": EligibilityRequestsIndex,
        "BackofficeEligibilityRequestsFon": EligibilityRequestsIndex,
        // Invoices
        "BackofficeInvoicesIndex": InvoiceIndex,
        "BackofficeInvoicesEdit": InvoiceForm,
        "BackofficeInvoicesNew": InvoiceForm,
        "BackofficeInvoicesUpdate": InvoiceForm,
        "BackofficeInvoicesCreate": InvoiceForm,
        // Downloadables
        "BackofficeDownloadablesNew": DownloadablesForm,
        "BackofficeDownloadablesEdit": DownloadablesForm,
        "BackofficeDownloadablesUpdate": DownloadablesForm,
        "BackofficeDownloadablesCreate": DownloadablesForm,
        // Products
        "BackofficeProductsNew": ProductNew,
        "BackofficeProductsEdit": ProductEdit,
        "BackofficeProductsIndex": ProductIndex,
        // Options
        "BackofficeOptionsIndex": OptionIndex,
        "BackofficeOptionsEdit": OptionIndex,
        // Entity Products
        "BackofficeEntityProductsIndex": BackofficeEntityProductsIndex,
        // Entity Options
        "BackofficeEntityOptionsIndex": BackofficeEntityOptionsIndex,
        // BO Orders
        "BackofficeOrdersShow": BackofficeOrdersShow,
        "BackofficeOrdersIndex": BackofficeOrdersIndex,
        "BackofficeOrdersNew": BackofficeOrdersNew,
        // BO Tasks
        "BackofficeTasksShow": BackofficeTasksShow,
        "BackofficeTasksOrderTasks": BackofficeTasksIndex,
        "BackofficeTasksStudyRequestsTasks": BackofficeTasksIndex,
        "BackofficeTasksClosedTasks": BackofficeTasksIndex,
        // BO shared files (like invoices)
        "BackofficeSharedFilesNew": SharedFileForm,
        "BackofficeSharedFilesEdit": SharedFileForm,
        "BackofficeSharedFilesUpdate": SharedFileForm,
        "BackofficeSharedFilesCreate": SharedFileForm,

        // Study Requests
        "BackofficeStudyRequestsIndex": BackofficeStudyRequestsIndex,

        // Workflows
        "BackofficeWorkflowsIndex": BackofficeWorkflowsIndex,
        "BackofficeWorkflowsShow": BackofficeWorkflowsShow,
        "BackofficeStepsShow": BackofficeStepsShow,
        "BackofficeStepsNew": BackofficeStepsNew,
        "BackofficeCustomFieldsEdit": BackofficeCustomFieldsForm,
        "BackofficeCustomFieldsNew": BackofficeCustomFieldsForm,

        // Services
        "BackofficeServicesIndex": BackofficeServicesIndex,

        // Eligibilities
        "BackofficeEligibilitiesIndex": BackofficeEligibilitiesIndex,
        "BackofficeEligibilitiesShow": BackofficeEligibilitiesShow,

        // Operations
        "BackofficeOperationsIndex": BackofficeOperationsIndex,

        // Entities
        "BackofficeEntitiesNew": BackofficeEntitiesForm,
        "BackofficeEntitiesEdit": BackofficeEntitiesForm,
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}