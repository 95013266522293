import DraggableTable from '../utils/DraggableTable';

export default class BackofficeWorkflowsShow extends DraggableTable {
  constructor() {
    super('draggable-tbody'); 
    new DraggableTable('draggable-tbody-modal');
    this.bindEvents();
  }

  bindEvents() {
    $(".toggle-step").off('change', this.toggleStep).on('change', this.toggleStep);
    $("#bo-preview, #extranet-preview").on('click', this.loadPreview.bind(this));
    $("#team-select, #entity-select").on('change', this.loadPreview.bind(this));
    $("#bo-preview").trigger('click');
    this.initializeServiceNumberFormatEditor();
  }

  updateOrder() {
    const rows = document.querySelectorAll(`#${this.tableId} tr`);
    const ids = Array.from(rows).map(row => row.getAttribute('data-id'));
    const url = $("#draggable-tbody").data('url');

    this.rearrangeRowsOrder(ids);

    $.ajax({
      url,
      method: 'PUT',
      data: { steps_ids: ids },
      success: (data) => this.handleOrderUpdateSuccess(data),
      error: () => this.showError("backoffice.workflows.update.error"),
    });
  }

  handleOrderUpdateSuccess(data) {
    const warning = $("#order-warning");
    data.valid_order ? warning.addClass("d-none") : warning.removeClass("d-none");
  }

  rearrangeRowsOrder(ids) {
    $(`#${this.tableId} tr`).each((index, row) => {
      $(row).find('td:nth-child(2)').text(index + 1);
    });
  }

  toggleStep(e) {
    const { dataset: { url }, checked } = e.target;

    $.ajax({
      url,
      method: 'PUT',
      data: { active: checked },
      success: () => this.showSuccess("Étape mise à jour avec succès"),
      error: () => this.showError("Erreur lors de la mise à jour de l'étape"),
    });
  }

  initializeServiceNumberFormatEditor() {
    const canAddComponent = () => $('#draggable-tbody-modal tr').length < 6;

    const createComponentRow = (type, placeholder = '', value = '', readonly = false) => $(`
      <tr draggable="true" class="border-bottom component ${type === 'date' ? 'date-field' : ''}">
        <td class="py-2"><i class="fas fa-grip-lines"></i></td>
        <td class="py-2">${type === 'string' ? 'Chaîne de caractères' : 'Date'}</td>
        <td class="py-2 text-left">
          <input type="text" class="form-control component" 
                 placeholder="${placeholder}" value="${value}" ${readonly ? 'readonly' : ''}>
          <button class="btn btn-danger ml-2 remove-component" type="button">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    `);

    const addComponent = (type) => {
      const tbody = $('#draggable-tbody-modal');
      if (type === 'string') {
        tbody.prepend(createComponentRow('string', 'Entrer une valeur'));
      } else if (type === 'date' && $('#draggable-tbody-modal #date-field').length === 0) {
        tbody.prepend(createComponentRow('date', '', 'DDMMYYYY', true));
        $('.add_date').prop('disabled', true);
      }

      $('.remove-component').off('click').on('click', function() {
        var isDateField = $(this).closest('tr').hasClass('date-field');
        
        if (isDateField) {
          $('.add_date').prop('disabled', false);
        }

        $(this).closest('tr').remove();
      });
    };

    $('.add_string').click(() => canAddComponent() && addComponent('string'));
    $('.add_date').click(() => canAddComponent() && addComponent('date'));
    $('.clear-format').click(this.clearFormat);

    $('.service-number-format-btn').click(this.saveServiceNumberFormat);
  }

  clearFormat() {
    $('#draggable-tbody-modal').children().not('#numerotation').remove();
    $('.add_date').prop('disabled', false);
  }

  saveServiceNumberFormat() {
  const self = this;

    function showAlert(message) {
      $('#alert-container').html(`
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
          ${message}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      `);
    }
  
    const url = $("#service-number-format").data('url');
    const components = $('#format-container .component')
      .map((_, el) => $(el).val().trim())
      .get()
      .filter(Boolean);
  
    if (components.length < 2) {
      showAlert("Le format doit inclure au moins 2 composants.");
      return;
    }
  
    const specialCharPattern = /[^a-zA-Z0-9]/;
    if (components.some(component => specialCharPattern.test(component))) {
      showAlert("Les composants ne doivent pas contenir de caractères spéciaux.");
      return;
    }
  
    const format = components.join('-');
    $.ajax({
      url,
      method: 'PUT',
      data: { format, start_number: $('#start_number').val() },
    });
  }

  loadPreview(e) {
    e.preventDefault();
    const url = $(e.target).data('url');
    const isBO = $(e.target).data('type') === 'backoffice';

    this.togglePreviewTabs(isBO);

    const data = isBO
      ? { team_id: $("#team-select").val() }
      : { entity_id: $("#entity-select").val() };

    $.ajax({
      url,
      method: 'GET',
      data,
      success: (data) => $("#preview-content").html(data),
      error: () => this.showError("Erreur lors du chargement de l'aperçu"),
    });
  }

  togglePreviewTabs(isBO) {
    $("#bo-preview").toggleClass('underline', isBO);
    $("#extranet-preview").toggleClass('underline', !isBO);
    $("#teams-select-wrapper").toggle(isBO);
    $("#entities-select-wrapper").toggle(!isBO);
  }

  showSuccess(message) {
    application.successNotification(message);
  }

  showError(message) {
    application.errorNotification(I18n.t(message));
  }

  onDataChanged(data) {}
  onDestroy() {}
}