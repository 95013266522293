export default class BackofficeOperationsIndex {
  constructor() {
    this.datatable = null;
    this.bindEvents();
  }

  bindEvents() {
    this.datatable = $("#operations-table").DataTable({
      "dom": '<"datatable-controls d-flex justify-content-between"lf>rtip', // Removed 'B' from the dom string
      "responsive": true,
      "searching": true,
      "columnDefs": [{
        "targets": -1,
        "orderable": true
      }],
      "order": [[0, "desc"]],
      "language": {
        url: '/assets/data-tables/locales/' + I18n.locale + '.json',
        previous: "<i class='mdi mdi-chevron-left'>",
        next: "<i class='mdi mdi-chevron-right>",
      }
    });
  }

  onDataChanged(data) {
    // Handle data changes
  }

  onDestroy() {
    if (this.datatable) {
      this.datatable.destroy();
      this.datatable = null;
    }
  }
}