import BackofficeOperationDataTable from '../utils/BackofficeOperationDataTable';

export default class BackofficeIndexBase {
  constructor(dataTableSelector) {
    this.dataTable = new BackofficeOperationDataTable(dataTableSelector);

    this.bindEvents();
  }

  bindEvents() {
    $('.duplicate-btn').on('click', function(event) {
      console.log('Duplicate button clicked');
      
      var orderId = $(this).data('id');
      var form = $('#duplicateForm');
      var actionUrl = form.attr('action');
  
      // Check if the id parameter is already present in the action URL
      if (!actionUrl.includes('?id=')) {
        actionUrl += '?id=' + orderId;
        form.attr('action', actionUrl);
      }
    });
  }

  onDataChanged(data) {
    // Custom logic for data changes
  }

  onDestroy() {
    this.dataTable.onDestroy();
  }
}