import DraggableTable from '../utils/DraggableTable';

export default class BackofficeStepsShow extends DraggableTable {
  constructor() {
    super('draggable-tbody');

    this.select2Options = {
      multiple: true,
      allowClear: true,
      placeholder: 'Choisir une ou plusieurs équipes',
      closeOnSelect: false
    };
    this.bindEvents();
  }

  bindEvents() {
    $(".steps-select").select2({
      placeholder: 'Choisir une ou plusieurs étapes',
      closeOnSelect: false,
      multiple: true
    });
    
    this.bindRowEvents();
    this.clickFirstRow();
    this.bindFormEvents();
    this.openDefaultForm();
    this.toggleStepFormWrapper();
    this.bindStepFormSwitch();
  }

  clickFirstRow() {
    const firstRow = $(`#${this.tableId} tr:first`);
    firstRow.click();
  }

  openDefaultForm() {
    if ($("#draggable-tbody").children().length === 0) {
      $("#add-action-btn").click();
    }
  }

  toggleStepFormWrapper() {
    if (!$("#custom-switch-step-form").prop('checked')) {
      $("#steps-select-wrapper").toggleClass('d-none');
    }
  }

  bindStepFormSwitch() {
    $("#custom-switch-step-form").on('change', () => {
      $("#steps-select-wrapper").toggleClass('d-none');
    });
  }

  bindRowEvents(id = null) {
    this.unbindDragEvents();
    this.bindDragEvents();
    this.bindActionRowClick();
    this.bindAddActionClick();

    if (id) {
      this.clickRowById(id);
    }
  }

  unbindDragEvents() {
    const tbody = document.getElementById(this.tableId);
    tbody.removeEventListener('dragstart', this.handleDragStart);
    tbody.removeEventListener('dragend', this.handleDragEnd);
    tbody.removeEventListener('dragover', this.handleDragOver);
    tbody.removeEventListener('dragleave', this.handleDragLeave);
    tbody.removeEventListener('drop', this.handleDrop);
  }

  bindActionRowClick() {
    $(".action-row").on('click', (event) => this.handleFormFetch(event));
  }

  bindAddActionClick() {
    $("#add-action-btn").on('click', (event) => this.addAction(event));
  }

  clickRowById(id) {
    const row = $(`.row-${id}`);
    row.click();
  }

  bindFormEvents() {
    this.bindFormSubmit('#edit_action_form');
    this.bindFormSubmit('#new_action_form', true);
    this.toggleActionSelectWrapper();
    this.bindCustomSwitch();
  }

  bindFormSubmit(formSelector, isNew = false) {
    $(formSelector).on('submit', (event) => this.handleFormSubmit(event, isNew));
  }

  toggleActionSelectWrapper() {
    if (!$("#custom-switch").prop('checked')) {
      $("#action-select-wrapper").toggleClass('d-none');
    }
  }

  bindCustomSwitch() {
    $("#custom-switch").on('change', () => {
      $("#action-select-wrapper").toggleClass('d-none');
    });
  }

  handleFormFetch(event, isNew = null) {
    const element = $(event.currentTarget);
    if (!isNew) {
      this.clearSelections();
      element.addClass('bg-light border-left-success');
    }

    const id = element.data('id');
    const url = element.data('url');

    $.ajax({
      url: url,
      method: 'GET',
      success: (data) => {
        $("#form-wrapper").html(data);
        this.bindFormEvents();
        this.initializeSelect2('.team-select', this.select2Options);
        this.initializeSelect2(".actions-select", {
          multiple: true,
          allowClear: true,
          placeholder: 'Choisir une ou plusieurs actions',
          closeOnSelect: false
        });
      }
    });
  }

  initializeSelect2(selector, options) {
    $(selector).each(function() {
      $(this).select2(options);
    });
  }

  handleFormSubmit(event, isNew = null) {
    event.preventDefault();
    const form = $(event.target);

    $.ajax({
      url: form.attr('action'),
      method: form.attr('method'),
      data: form.serialize(),
      success: (data) => {
        application.successNotification(data.message);
        this.refreshListWrapper(data.action_id, isNew, form);
      },
      error: (data) => {
        application.errorNotification(data.message);
      }
    });
  }

  refreshListWrapper(actionId, isNew, form) {
    const listWrapper = $(".list-wrapper");
    const url = listWrapper.data('url');

    if (isNew) {
      this.clearFormFields(form);
    }

    $.ajax({
      url: url,
      method: 'GET',
      success: (listData) => {
        listWrapper.html(listData);
        this.bindRowEvents(actionId);
      }
    });
  }

  clearFormFields(form) {
    form.find('input[type="text"], input[type="password"], input[type="email"], textarea').val('');
    form.find('input[type="checkbox"], input[type="radio"]').prop('checked', false);
    form.find('select').prop('selectedIndex', 0);
  }

  clearSelections() {
    $(".action-row").removeClass('bg-light border-left-success');
  }

  updateOrder() {
    const rows = document.querySelectorAll(`#${this.tableId} tr`);
    const ids = Array.from(rows).map(row => row.getAttribute('data-id'));
    const url = $("#draggable-tbody").data('url');

    $.ajax({
      url: url,
      method: 'PUT',
      data: { actions_ids: ids },
      error: () => {
        application.errorNotification(I18n.t("backoffice.workflows.update.error"));
      },
      success: function(data){
        if (!data.valid_order){
          $("#order-warning").removeClass("d-none");
        }else{
          $("#order-warning").addClass("d-none");
        }
      }
    });
  }

  addAction(event) {
    this.clearSelections();
    this.handleFormFetch(event, true);
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $(".steps-select").select2('destroy');
  }
}